<template>
  <div class="slider">
    <div id="container"><h1 class="heading3">Trusted By</h1></div>
    <div class="cusslider" v-if="loader">
      <VueSlickCarousel v-bind="settings">
        <img
          class="imgslid"
          :key="index"
          v-for="(customer, index) in Customers"
          :src="customer.images"
          alt=""
        />
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

import { db } from "../firebase.js";
export default {
  components: { VueSlickCarousel },
  name: "Slider",
  data() {
    return {
      Customers: [],
      loader: false,
      settings: {
        autoplay: true,
        arrows: false,
        dots: false,
        infinite: true,
        slidesToShow: 7,
        pauseOnHover: false,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        responsive: [
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 4
            }
          },
          {
            breakpoint: 780,
            settings: {
              slidesToShow: 3
            }
          },
          {
            breakpoint: 580,
            settings: {
              slidesToShow: 2
            }
          }
        ]
      }
    };
  },
  created() {
    db.collection("Customers")
      // .orderBy("type", "asc")
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          let customer = doc.data();
          customer.id = doc.id;
          this.Customers.push(customer);
        });
      })
      .then(() => (this.loader = true));
  }
};
</script>

<style lang="scss" scoped>
@import url(https://fonts.googleapis.com/css?family=Oswald);
.style-3 {
  font-family: "Oswald", sans-serif;
}

#container {
  margin: 0 auto;
  max-width: 520px;
  text-align: center;
}

.heading3 {
  overflow: hidden;
  text-align: center;
  color: #010181;
  text-transform: uppercase;
  // letter-spacing: 3px;
}

.heading3:before,
.heading3:after {
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  top: -2px;
  vertical-align: middle;
}

.heading3:before {
  background-color: #010181;
  left: -0.5em;
  margin-left: -100%;
  width: 100%;
}

.heading3:after {
  background-color: #010181;
  left: 0.5em;
  margin-right: -100%;
  width: 100%;
}

.imgslid {
  max-width: 80%;
  max-height: 90px;
  min-height: 70px;
}

.slider {
  margin-bottom: 20px;
}
@mixin white-gradient {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.cusslider {
  position: relative;
  &::before,
  &::after {
    @include white-gradient;
    content: "";
    height: 100px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    left: 0;
    top: 0;
  }
}
</style>

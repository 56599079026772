import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";
import AOS from "aos";
import "aos/dist/aos.css";
import BackToTop from "vue-backtotop";
import VueMeta from "vue-meta";

Vue.use(VueMeta);
Vue.use(Viewer);
Vue.config.productionTip = false;
Vue.use(BackToTop);

new Vue({
  created() {
    AOS.init();
  },
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");

<template>
  <div class="nav">
    <nav>
      <router-link to="/">
        <img src="../assets/logo with name.png" alt="Logo" class="logo"
      /></router-link>
      <ul class="nav-links">
        <li><router-link to="/">Home</router-link></li>
        <li>
          <router-link to="/About">About Us</router-link>
        </li>
        <li>
          <router-link to="/Infrastructure">Infrastructure</router-link>
        </li>
        <li>
          <router-link to="/Services">Services</router-link>
        </li>
        <li>
          <router-link to="/Gallery">Gallery</router-link>
        </li>
        <li>
          <router-link to="/Contactus">Contact Us</router-link>
        </li>
      </ul>

      <div class="burger">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      </div>
      <img src="../assets/img/25yrs.png" alt="Logo" class="anniversary" />
    </nav>
    <div class="mobview yeah">
      <v-navigation-drawer v-model="drawer" fixed right>
        <v-list nav sty>
          <v-list-item-group
            v-model="group"
            active-class="deep-purple--text text--accent-4"
          >
            <v-list-item to="/">
              <v-list-item-title>Home</v-list-item-title>
            </v-list-item>

            <v-list-item to="/About">
              <v-list-item-title>About Us</v-list-item-title>
            </v-list-item>

            <v-list-item to="/Infrastructure">
              <v-list-item-title>Infrastructure</v-list-item-title>
            </v-list-item>

            <v-list-item to="/Services">
              <v-list-item-title>Services</v-list-item-title>
            </v-list-item>

            <v-list-item to="/Gallery">
              <v-list-item-title>Gallery</v-list-item-title>
            </v-list-item>

            <v-list-item to="/Contactus">
              <v-list-item-title>Contact</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </div>
  </div>
</template>
<script>
export default {
  name: "Navbar",
  data() {
    return {
      hover: false,
      drawer: false,
      group: null
    };
  },
  watch: {
    group() {
      this.drawer = false;
    }
  }
};
</script>
<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

nav {
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 12%;
  background-color: rgba(0, 0, 0, 0.08);
}

.logo {
  top: 2%;
  height: auto;
  width: auto;
  max-height: 900px;
  max-width: 200px;
  /* vertical-align: middle; */
}

/* .v-navigation-drawer {
  display: none;
} */

.nav-links {
  position: absolute;
  right: 10%;
  display: flex;
  justify-content: space-around;
  width: 50%;
  top: 28px;
}

.nav-links li {
  list-style: none;
}

.nav-links a {
  color: #010181 !important;
  text-decoration: none;
  letter-spacing: 3px;
  font-size: 14px;
}
.nav-links a:hover {
  color: rgb(0, 0, 0) !important;
  text-decoration: none;
  font-weight: bold;
}
a.router-link-exact-active {
  color: rgb(0, 0, 0) !important;
  font-weight: bold;
}
.burger {
  display: none;
}
.anniversary {
  height: auto;
  width: auto;
  max-height: 70px;
  max-width: 100px;
  float: right;
}

.burger div {
  width: 25px;
  height: 3px;
  background-color: #010181;
  margin: 5px;
  transition: all 0.3s ease;
}
a {
  color: #010181 !important;
  text-decoration: none;
}
.v-list-item {
  min-height: 70px !important;
  text-align: center;
}

@media screen and (max-width: 1144px) {
  .nav-links {
    width: 65%;
  }
}
@media screen and (min-width: 938px) {
  .mobview {
    display: none;
  }
}

@media screen and (max-width: 938px) {
  body {
    overflow-x: hidden;
  }
  .burger {
    position: absolute;
    right: 10%;
    display: block;
    cursor: pointer;
    top: 4%;
  }
  .nav-links {
    display: none;
  }
  .v-navigation-drawer {
    width: 200px !important;
    top: 13vh !important;
  }
  .theme--light.v-navigation-drawer {
    background-color: rgba(255, 255, 255, 0.25) !important;
    backdrop-filter: blur(8px);
  }
  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    text-decoration: none !important;
  }
  a:hover {
    text-decoration: none !important;
  }
}
@media screen and (max-width: 450px) {
  .nav-links {
    top: 13vh;
    width: 40%;
  }
  nav {
    height: 13vh;
  }
}

@media (min-width: 1145px) {
  .logo {
    max-height: 1000px;
    max-width: 300px;
    /* vertical-align: middle; */
  }
  .anniversary {
    margin: 1%;
  }
  .nav-links {
    top: 48px;
  }
}

@media screen and (max-width: 940px) {
  .anniversary {
    display: none;
  }
}
</style>

<template>
  <div>
    <footer class="footer">
      <div class="footeraddr">
        <h1 class="footerlogo">
          <img
            src="../assets/logo.png"
            width="140px"
            height="120px"
            alt="logo"
          />
        </h1>

        <address>
          <a>Since1996!</a>
          <a style="display: block">ISO 9001 - 2015 Certified</a>
        </address>
      </div>

      <ul class="footernav">
        <li class="navitem">
          <h2 class="navtitle">Quick Links</h2>

          <ul class="navul">
            <li>
              <router-link
                :to="{
                  name: 'Gallery',
                }"
              >
                Gallery</router-link
              >
            </li>
            <li>
              <router-link
                :to="{
                  name: 'Services',
                }"
              >
                Services</router-link
              >
            </li>
            <li>
              <router-link
                :to="{
                  name: 'Contactus',
                }"
              >
                Contact Us</router-link
              >
            </li>
            <li>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/mano-tools.appspot.com/o/ManoTools%2FBrochure.pdf?alt=media&token=d38bfce1-de3c-4d2b-bf7a-22154e9fa3e8"
                target="_blank"
                >Brochure</a
              >
            </li>
          </ul>
        </li>

        <li class="navitem navitem--extra">
          <h2 class="navtitle">About ManoTools</h2>
          <ul class="navul">
            <li>
              <router-link :to="{ name: 'About' }">About Us</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Infrastructure' }"
                >Infrastructure</router-link
              >
            </li>
            <li>
              <router-link
                :to="{
                  name: 'Carrer',
                }"
              >
                Carrers</router-link
              >
            </li>
          </ul>
        </li>

        <li class="navitem">
          <h2 class="navtitle">Follow Us On</h2>

          <ul class="navul">
            <li>
              <a
                href="https://www.linkedin.com/company/manotools"
                target="_blank"
                rel="noopener noreferrer"
                ><i class="bx bxl-linkedin" style="color: #010181"></i
              ></a>
              <a
                href="https://www.facebook.com/MANOTOOLS"
                target="_blank"
                rel="noopener noreferrer"
                ><i class="bx bxl-facebook" style="color: #010181"></i
              ></a>

              <a
                href="https://wa.me/8610190094/"
                target="_blank"
                rel="noopener noreferrer"
                ><i class="bx bxl-whatsapp" style="color: #010181"></i
              ></a>

              <br />
              <a
                href="https://twitter.com/mano_tools"
                target="_blank"
                rel="noopener noreferrer"
                ><i class="bx bxl-twitter" style="color: #010181"></i
              ></a>
              <a
                href="https://www.youtube.com/channel/UCIVW6ZUmRAWSKGDH624J8aQ"
                target="_blank"
                rel="noopener noreferrer"
                ><i class="bx bxl-youtube" style="color: #010181"></i
              ></a>
              <a
                href="https://www.instagram.com/manotools/"
                target="_blank"
                rel="noopener noreferrer"
                ><i class="bx bxl-instagram" style="color: #010181"></i
              ></a>
            </li>
          </ul>
        </li>
      </ul>
      <div class="footer-copyright text-center py-3">
        &copy;ManoTools. All rights reserved<br />
        Developed By
        <a
          href="https://hictous.netlify.app/"
          target="_blank"
          rel="noopener noreferrer"
        >
          HICTOUS</a
        >
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Footer",

  //   methods:{
  //   openpdf: function(){
  //   var pdf = '/src/assets/Brochure.pdf';
  //   window.open(pdf);
  // }
  //   }
};
</script>

<style lang="css" scoped>
*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font-size: 100%;
}

body {
  font-family: acumin-pro, system-ui, sans-serif;
  margin: 0;
  display: grid;
  grid-template-rows: auto 1fr auto;
  font-size: 14px;
  background-color: #f4f4f4;
  align-items: start;
  min-height: 100vh;
}
a {
  color: #010181;
  font-size: 14px;
}
.footer {
  display: flex;
  /* margin-top: 450px; */
  flex-flow: row wrap;
  padding: 30px 30px 20px 30px;
  color: #2f2f2f;
  background-color: rgba(0, 0, 0, 0.08);
  border-top: 1px solid #e5e5e5;
}

.footer > * {
  flex: 1 100%;
}

.footeraddr {
  margin-right: 1.25em;
  margin-bottom: 2em;
}
@media screen and (max-width: 645px) {
  .footeraddr {
    margin-right: 0em;
  }
}

.footerlogo {
  text-align: center;
  /* margin-left: 40%; */
}

.footeraddr h2 {
  margin-top: 1.3em;
  font-size: 15px;
  font-weight: 400;
}

.navtitle {
  word-spacing: 3px;
  line-height: 200%;
  font-weight: 400;
  font-size: 15px;
}
#contact {
  margin-bottom: 8px;
}
.footer address {
  font-style: normal;
  color: #010181;
  text-align: center;
}

.footer address a {
  text-decoration: none;
}

.footer ul {
  list-style: none;
  padding-left: 0;
}

.footer li {
  line-height: 2em;
}

.footer a {
  text-decoration: none;
}

.footernav {
  display: flex;
  flex-flow: row wrap;
}

.footernav > * {
  flex: 1 50%;
  margin-right: 1.25em;
}

.navul a {
  color: #010181;
}

.navul--extra {
  column-count: 1;
  column-gap: 1.25em;
}

.copyrights {
  display: flex;
  flex-wrap: wrap;
  color: #999;
  justify-content: center;
  margin-top: 20px;
}

.heart {
  color: #2f2f2f;
}

i {
  font-size: 20px;
  padding-right: 20px;
}

@media screen and (max-width: 400px) {
  .footer {
    display: flex;
    text-align: center;
    flex-flow: column wrap;
    padding: 30px 30px 20px 30px;
    color: #2f2f2f;
    border-top: 1px solid #e5e5e5;
  }
}
@media screen and (min-width: 0px) and (max-width: 650px) {
  .footer {
    display: flex;
    text-align: center;
    /* margin-top: 500px; */
    flex-flow: column wrap;
    padding: 30px 30px 20px 30px;
    color: #2f2f2f;
    background-color: #fff;
    border-top: 1px solid #e5e5e5;
  }
  .copyrights {
    display: flex;
    flex-wrap: wrap;
    color: #999;
  }
}

@media screen and (min-width: 40.375em) {
  .footernav > * {
    flex: 1;
  }

  .navitem--extra {
    flex-grow: 1;
  }

  .footeraddr {
    flex: 1 0px;
  }

  .footernav {
    flex: 2 0px;
  }
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Vuelidate from "vuelidate";

Vue.use(Vuelidate);

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },

  {
    path: "/About",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/Infrastructure",
    name: "Infrastructure",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/infrastructure.vue")
  },
  {
    path: "/Services",
    name: "Services",
    component: () => import("../views/Services.vue")
  },
  {
    path: "/Gallery",
    name: "Gallery",
    component: () => import("../views/Gallery.vue")
  },
  {
    path: "/Contactus",
    name: "Contactus",
    meta: { Slider: false },
    component: () => import("../views/Contactus.vue")
  },
  {
    path: "/Carrers",
    name: "Carrer",
    meta: { Slider: false },
    component: () => import("../views/Carrer.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  }
});

export default router;

<template>
  <div id="app">
    <Navbar />
    <back-to-top bottom="20px" right="20px">
      <button type="button" class="btn btn-info btn-to-top">
        <i class="bx bxs-arrow-to-top"></i>
      </button>
    </back-to-top>
    <transition
      name="router-animation"
      enter-active-class="animate__fadeIn"
      leave-active-class="animate__fadeOut"
      mode="out-in"
    >
      <router-view />
    </transition>
    <Slider v-if="shouldShowSlider" />
    <Footer />
  </div>
</template>
<script>
import Navbar from "./components/navbar";
import Footer from "./components/Footer";
import Slider from "./components/Slider";

export default {
  name: "root",
  components: {
    Navbar,
    Footer,
    Slider
  },
  computed: {
    shouldShowSlider() {
      return this.$route.meta.Slider !== false;
    }
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Home",
    // all titles will be injected into this template
    titleTemplate: "ManoTools - %s"
  }
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}
.page-wrapper {
  animation-duration: 0.4s;
}
.anniversary-pop {
  width: 100%;
  height: 300px;
}
.v-application {
  & .title {
    font-size: unset !important;
    font-weight: unset !important;
    letter-spacing: unset !important;
  }

  & a {
    color: unset !important;
  }
}
.v-application--wrap {
  position: unset !important;
}
.btn-to-top {
  border-color: #010181 !important;
  background-color: #010181 !important;
  width: 50px;
  height: 50px;
  padding: 10px 16px;
  border-radius: 50% !important;
  font-size: 22px;
  line-height: 22px;
}
</style>

import firebase from "firebase/app";
import "firebase/storage";
let config = require("../env.json");
require("firebase/firestore");

var firebaseConfig = {
  apiKey: config.service.apiKey,
  authDomain: config.service.authDomain,
  projectId: config.service.projectId,
  storageBucket: config.service.storageBucket,
  messagingSenderId: config.service.messagingSenderId,
  appId: config.service.appId
};

var fb = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
export { fb, db };
